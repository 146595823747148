import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { MsalProvider } from "@azure/msal-react";
import {
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import HomeScreen from "./screens/HomeScreen";
import AudioVisualScreen from "./screens/AudioVisualScreen";
import { Screens } from "./enums/Screens";
import NavigationBarComponent from "./components/NavigationBarComponent";
import { ContentComponent } from "./components/ContentComponent";
import { StorageService } from "./services/StorageService";
import { MiscStorageKeys } from "./enums/StorageKeys";
import { msalConfig } from "./MsalConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  const payload = event.payload as AuthenticationResult;
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    payload.account
  ) {
    msalInstance.setActiveAccount(payload.account);
  }
});

export default function App() {
  const { t } = useTranslation();

  const savedSelectedScreenId =
    StorageService().getWithDefaultFromSessionStorage(
      MiscStorageKeys.SelectedScreenId,
      () => Screens.Home,
    );

  const [selectedScreenId, setSelectedScreenId] = useState<Screens>(
    savedSelectedScreenId,
  );

  function changeScreen(value: Screens): void {
    setSelectedScreenId(value);
    StorageService().setToSessionStorage(
      MiscStorageKeys.SelectedScreenId,
      value,
    );
  }

  const notFoundElement = (
    <>
      <Typography variant="h3">{t("navigationError")}</Typography>
      <Button
        onClick={() => {
          StorageService().clearAll();
          changeScreen(Screens.Home);
        }}
      >
        {t("resetStorageButton")}
      </Button>
    </>
  );

  return (
    <MsalProvider instance={msalInstance}>
      <>
        <NavigationBarComponent
          selectedValue={selectedScreenId}
          changeScreenCallback={changeScreen}
        />
        <ContentComponent
          selectedContentId={selectedScreenId}
          notFoundElement={notFoundElement}
        >
          <HomeScreen contentId={Screens.Home} />
          <AudioVisualScreen contentId={Screens.AudioVisual} />
        </ContentComponent>
      </>
    </MsalProvider>
  );
}
