import { Children, ReactElement } from "react";
import { ContentProps, Id } from "../types/Content";

export function ContentComponent(contentProps: ContentProps) {
  const items = Children.toArray(contentProps.children) as ReactElement<Id>[];
  const item = items.find(
    (i) => i.props.contentId === contentProps.selectedContentId,
  );

  return <>{item || contentProps.notFoundElement}</>;
}
