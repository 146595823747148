import { LogLevel } from "@azure/msal-browser";
import { getEnvironment } from "./Config";

const environment = getEnvironment();

export const msalConfig = {
  auth: {
    clientId: environment.adb2c_client_id,
    authority: environment.adb2c_authority,
    knownAuthorities: environment.adb2c_known_authorities,
    redirectUri: environment.adb2c_redirect_uri,
    postLogoutRedirectUri: "https://localhost:{port}/redirect",
    navigateToLoginRequestUrl: true,
  },

  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },

  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean,
      ): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            break;
          case LogLevel.Info:
            // console.info(message);
            break;
          case LogLevel.Verbose:
            // console.debug(message);
            break;
          case LogLevel.Warning:
            // console.warn(message);
            break;
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
  },
};
