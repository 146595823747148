import moment from "moment";
import { StorageValue } from "../types/StorageTypes";
import { Timespan } from "../types/Timespan";

export function StorageService() {
  function getFromLocalStorage<T>(key: string): T | undefined {
    return _getFromStorage(localStorage, key, undefined);
  }

  function getWithDefaultFromLocalStorage<T>(
    key: string,
    defaultValue: () => T,
  ): T {
    return _getFromStorage(localStorage, key, defaultValue)!;
  }

  function setToLocalStorage<T>(key: string, value: T, timeToLive?: Timespan) {
    _setToStorage(localStorage, key, value, timeToLive);
  }

  function appendToLocalStorage<T>(
    key: string,
    value: T,
    timeToLive?: Timespan,
  ) {
    _appendToStorage(localStorage, key, value, timeToLive);
  }

  function removeFromLocalStorage(key: string) {
    _removeFromStorage(localStorage, key);
  }

  function getFromSessionStorage<T>(key: string): T | undefined {
    return _getFromStorage(sessionStorage, key, undefined);
  }

  function getWithDefaultFromSessionStorage<T>(
    key: string,
    defaultValue: () => T,
  ): T {
    return _getFromStorage(sessionStorage, key, defaultValue)!;
  }

  function setToSessionStorage<T>(
    key: string,
    value: T,
    timeToLive?: Timespan,
  ) {
    _setToStorage(sessionStorage, key, value, timeToLive);
  }

  function appendToSessionStorage<T>(
    key: string,
    value: T,
    timeToLive?: Timespan,
  ) {
    _appendToStorage(sessionStorage, key, value, timeToLive);
  }

  function removeFromSessionStorageValue<T>(
    key: string,
    removeFilter: (item: T) => void,
  ) {
    _removeFromStorageValue(sessionStorage, key, removeFilter);
  }

  function removeFromSessionStorage(key: string) {
    _removeFromStorage(sessionStorage, key);
  }

  function clearAll() {
    sessionStorage.clear();
    localStorage.clear();
  }

  function _appendToStorage<T>(
    storage: Storage,
    key: string,
    value: T,
    timeToLive?: Timespan,
  ) {
    const existingValue: T | undefined = _getFromStorage(
      storage,
      key,
      undefined,
    );

    if (existingValue && Array.isArray(value)) {
      _setToStorage(storage, key, value.concat(existingValue), timeToLive);
    } else if (!existingValue) {
      _setToStorage(storage, key, value, timeToLive);
    } else {
      _setToStorage(storage, key, { ...existingValue, ...value }, timeToLive);
    }
  }

  function _removeFromStorageValue<T>(
    storage: Storage,
    key: string,
    removeFilter: (item: T) => void,
  ) {
    const existing = storage.getItem(key);
    if (existing) {
      const storageData = JSON.parse(existing);
      if (_hasExpired(storageData)) {
        return;
      }

      removeFilter(storageData.value);
      _setToStorage(storage, key, storageData.value, storageData.timeToLive);
    }
  }

  function _getFromStorage<T>(
    storage: Storage,
    key: string,
    defaultValue?: () => T,
  ) {
    const storedData = storage.getItem(key);
    if (!storedData || storedData === "undefined") {
      if (!defaultValue) {
        return undefined;
      }

      const value = defaultValue();
      setToLocalStorage(key, value);
      return value;
    }

    const storageValue: StorageValue<T> = JSON.parse(storedData);
    if (_hasExpired(storageValue)) {
      return (defaultValue && defaultValue()) || undefined;
    }

    return storageValue.value;
  }

  function _hasExpired<T>(storageValue: StorageValue<T>): boolean {
    if (!storageValue.timeToLiveMs) {
      return false;
    }

    const utcNow = moment.utc().toDate();
    return (
      utcNow.getTime() - new Date(storageValue.timestamp).getTime() >=
      storageValue.timeToLiveMs
    );
  }

  function _setToStorage<T>(
    storage: Storage,
    key: string,
    value: T,
    timeToLive?: Timespan,
  ) {
    const storageItem: StorageValue<T> = {
      timestamp: moment.utc().toDate(),
      value,
      timeToLiveMs: timeToLive?.totalMilliseconds(),
    };

    storage.setItem(key, JSON.stringify(storageItem));
  }

  function _removeFromStorage(storage: Storage, key: string) {
    storage.removeItem(key);
  }

  return {
    getFromLocalStorage,
    getWithDefaultFromLocalStorage,
    setToLocalStorage,
    getFromSessionStorage,
    getWithDefaultFromSessionStorage,
    setToSessionStorage,
    appendToLocalStorage,
    appendToSessionStorage,
    removeFromSessionStorageValue,
    clearAll,
    removeFromSessionStorage,
    removeFromLocalStorage,
  };
}
