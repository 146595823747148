// interface TimeSpanParams {
//     hours?: number, minutes?: number, seconds?: number, milliseconds?: number
// }

export class Timespan {
  readonly hours: number;
  readonly minutes: number;
  readonly seconds: number;
  readonly milliseconds: number;

  constructor(params: {
    hours?: number;
    minutes?: number;
    seconds?: number;
    milliseconds?: number;
  }) {
    this.hours = params.hours || 0;
    this.minutes = params.minutes || 0;
    this.seconds = params.seconds || 0;
    this.milliseconds = params.milliseconds || 0;
  }

  totalMilliseconds = () => {
    const calculatedMilliseconds =
      (this.hours * 3600 + this.minutes * 60 + this.seconds) * 1000;

    return calculatedMilliseconds + this.milliseconds;
  };
}
