import {
  AddAudioVisualDto,
  ApiClientConfig,
  AudioVisualClient,
  AudioVisualDto,
  AudioVisualTypeEnum,
  StringPrimitiveResponse,
} from "../ApiClient";
import { ApiResult } from "../types/ApiResult";
import { handleApiResponse } from "./BaseController";

export function AudioVisualController() {
  const apiClient = new AudioVisualClient(new ApiClientConfig());

  function getAudioVisualByType(
    type: AudioVisualTypeEnum,
  ): Promise<ApiResult<AudioVisualDto[]>> {
    return handleApiResponse(apiClient.getByType(type));
  }

  function getAudioVisuals(): Promise<ApiResult<AudioVisualDto[]>> {
    return handleApiResponse(apiClient.getAll());
  }

  function addAudioVisual(
    audioVisual: AddAudioVisualDto,
  ): Promise<ApiResult<AudioVisualDto>> {
    return handleApiResponse(apiClient.add(audioVisual));
  }

  function deleteAudioVisual(
    audioVisualId: number,
  ): Promise<ApiResult<StringPrimitiveResponse>> {
    return handleApiResponse(apiClient.delete(audioVisualId));
  }

  return {
    getAudioVisualByType,
    getAudioVisuals,
    addAudioVisual,
    deleteAudioVisual,
  };
}
