import config from "./app.json";

function getApiEnvironment() {
  return {
    adb2c_client_id:
      process.env.REACT_APP_APP_AZUREADB2C_CLIENT_ID ??
      config.App.AzureAdB2C.ClientId,
    adb2c_redirect_uri:
      process.env.REACT_APP_APP_AZUREADB2C_REDIRECT_URI ??
      config.App.AzureAdB2C.RedirectUri,
    adb2c_login_scopes:
      process.env.REACT_APP_APP_AZUREADBC2_LOGIN_SCOPES ??
      config.App.AzureAdB2C.LoginScopes,
    adb2c_token_scopes:
      process.env.REACT_APP_APP_AZUREADBC2_TOKEN_SCOPES ??
      config.App.AzureAdB2C.TokenScopes,
    apiBaseUrl: process.env.REACT_APP_APP_API_BASE_URL ?? config.App.ApiBaseUrl,
  };
}

export function getEnvironment(): EnvironmentConfig {
  const apiEnvironment = getApiEnvironment();

  const defaultEnv: EnvironmentConfig = {
    adb2c_authority: config?.adb2c_authority,
    adb2c_known_authorities: config?.adb2c_known_authorities?.split(";"),
    adb2c_login_scopes: apiEnvironment.adb2c_login_scopes?.split(";"),
    adb2c_token_scopes: apiEnvironment.adb2c_token_scopes?.split(";"),
    adb2c_signup_signin_policy: config?.adb2c_signup_signin_policy,
    apiBaseUrl: apiEnvironment.apiBaseUrl,
    adb2c_client_id: apiEnvironment.adb2c_client_id,
    adb2c_redirect_uri: apiEnvironment.adb2c_redirect_uri,
  };

  return {
    ...defaultEnv,
  };
}

export interface EnvironmentConfig {
  apiBaseUrl: string;
  adb2c_authority: string;
  adb2c_known_authorities: string[];
  adb2c_redirect_uri: string;
  adb2c_login_scopes: string[];
  adb2c_token_scopes: string[];
  adb2c_signup_signin_policy: string;
  adb2c_client_id: string;
}

export interface ApiEnvironment {
  id: string;
  apiBaseUrl: string;
  adb2c_client_id: string;
  adb2c_redirect_uri: string;
}
