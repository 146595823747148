import {
  ApiClientConfig,
  AudioVisualSourceDto,
  AudioVisualTypeDto,
  GenreDto,
  MasterDataClient,
} from "../ApiClient";
import { ApiResult } from "../types/ApiResult";
import { handleApiResponse } from "./BaseController";

export function MasterDataController() {
  const apiClient = new MasterDataClient(new ApiClientConfig());

  function getAudioVisualTypes(): Promise<ApiResult<AudioVisualTypeDto[]>> {
    return handleApiResponse(apiClient.getAudioVisualTypes());
  }

  function getGenres(): Promise<ApiResult<GenreDto[]>> {
    return handleApiResponse(apiClient.getGenres());
  }

  function getAudioVisualSources(): Promise<ApiResult<AudioVisualSourceDto[]>> {
    return handleApiResponse(apiClient.getAudioVisualSources());
  }

  return { getAudioVisualTypes, getGenres, getAudioVisualSources };
}
