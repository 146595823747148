import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { getEnvironment } from "../Config";

export default function LoginComponent() {
  const { t } = useTranslation();

  const { instance } = useMsal();
  const [tokenStatus, setTokenStatus] = useState(
    instance.getActiveAccount() ? "Logged in" : undefined,
  );

  const environment = getEnvironment();

  async function login() {
    instance
      .acquireTokenPopup({
        scopes: environment.adb2c_token_scopes,
      })
      .then((response) => {
        if (response.accessToken) {
          setTokenStatus("Acquired Token");
        } else {
          setTokenStatus("Failed token acquisition\n");
        }
      })
      .catch((err) => {
        setTokenStatus("Failed token acquisition\n");
        console.error(err);
      });
  }

  return (
    <>
      <Button onClick={() => login()}>{`${t("loginButton")}`}</Button>
      <Typography>{tokenStatus}</Typography>
    </>
  );
}
