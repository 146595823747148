import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { Loading } from "../types/Component";
import { AudioVisualDto, AudioVisualTypeEnum } from "../api/ApiClient";
import { AudioVisualService } from "../services/AudioVisualService";
import { Id } from "../types/Content";
import { useOnUnload } from "../hooks/navigation";

export function AudioVisualListComponent(props: Loading & Id) {
  const [movies, setMovies] = useState<AudioVisualDto[]>();
  const [series, setSeries] = useState<AudioVisualDto[]>();
  const [hasLoadingErrors, setHasLoadingErrors] = useState(false);
  const { t } = useTranslation();

  useOnUnload(() => {
    AudioVisualService().clearAudioVisualStorage();
  });

  async function loadAudioVisuals(isReload: boolean) {
    try {
      setHasLoadingErrors(false);
      props.isLoadingCallback(true);

      setMovies(undefined);
      setSeries(undefined);
      const fetchedAudioVisuals =
        await AudioVisualService().getAudioVisuals(isReload);

      setMovies(
        fetchedAudioVisuals.filter(
          (av) => av.audioVisualType === AudioVisualTypeEnum.Movie,
        ),
      );

      setSeries(
        fetchedAudioVisuals.filter(
          (av) => av.audioVisualType === AudioVisualTypeEnum.Series,
        ),
      );
    } catch (error) {
      setHasLoadingErrors(true);
      console.log(error);
      //   setMovieFetchStatus((error as Error).message);
    } finally {
      props.isLoadingCallback(false);
    }
  }

  async function handleDeleteClicked(item: AudioVisualDto) {
    try {
      props.isLoadingCallback(true);
      await AudioVisualService().deleteAudioVisual(item.id);
    } catch (error) {
      console.log(error);
    } finally {
      props.isLoadingCallback(false);
      await loadAudioVisuals(false);
    }
  }

  useEffect(() => {
    loadAudioVisuals(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          // maxWidth: 360,
          bgcolor: "background.paper",
          justifyContent: "flex-start",
        }}
      >
        {hasLoadingErrors ? (
          <Typography>{t("problemsLoadingAudioVisuals")}</Typography>
        ) : (
          <Grid container>
            <Grid item xs={6}>
              {" "}
              <List
                sx={{
                  bgcolor: "background.paper",
                  border: "2px",
                }}
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {t("movie") + t("plural")}
                  </ListSubheader>
                }
              >
                {movies?.map((movie) => (
                  <ListItem
                    key={movie.id}
                    disablePadding
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(a) => handleDeleteClicked(movie)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText>
                      <Typography>{`${movie.name}`}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>{" "}
            </Grid>

            <Grid item xs={6}>
              <List
                sx={{
                  bgcolor: "background.paper",
                  border: "2px",
                }}
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    {t("serie") + t("plural")}
                  </ListSubheader>
                }
              >
                {series?.map((serie) => (
                  <ListItem
                    key={serie.id}
                    disablePadding
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(a) => handleDeleteClicked(serie)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemText>
                      <Typography>{`${serie.name}`}</Typography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}
