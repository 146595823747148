import {
  AudioVisualSourceDto,
  AudioVisualTypeDto,
  AudioVisualTypeEnum,
  GenreDto,
} from "../api/ApiClient";
import { MasterDataController } from "../api/controllers/MasterDataController";
import { ApiResult } from "../api/types/ApiResult";
import { DataStorageKeys, MasterDataStorageKeys } from "../enums/StorageKeys";
import { Timespan } from "../types/Timespan";
import { StorageService } from "./StorageService";

export function MasterDataService() {
  async function getAudioVisualTypes(
    isReload: boolean,
  ): Promise<AudioVisualTypeDto[]> {
    const result = await _getMasterData(
      isReload,
      MasterDataStorageKeys.AudioVisualTypes,
      MasterDataController().getAudioVisualTypes,
    );

    if (!result.isSuccess) {
      throw new Error(`Unable to fetch audioVisualTypes ${result.message}`);
    }

    // let audioVisualTypes = StorageService().getFromLocalStorage<
    //   AudioVisualTypeDto[]
    // >(MasterDataStorageKeys.AudioVisualTypes);

    // if (!audioVisualTypes || isReload) {
    //   const fetchedAudioVisualTypes =
    //     await MasterDataController().getAudioVisualTypes();
    //   if (!fetchedAudioVisualTypes.success) {
    //     throw new Error(
    //       `Unable to fetch audioVisualTypes ${fetchedAudioVisualTypes.message}`,
    //     );
    //   }

    //   audioVisualTypes = fetchedAudioVisualTypes.data;
    //   StorageService().setToLocalStorage<AudioVisualTypeDto[]>(
    //     MasterDataStorageKeys.AudioVisualTypes,
    //     audioVisualTypes,
    //     new Timespan({ minutes: 10 }),
    //   );
    // }

    // return audioVisualTypes;

    return result.data;
  }

  async function getGenres(isReload: boolean): Promise<GenreDto[]> {
    // const genres = StorageService().getFromLocalStorage<GenreDto[]>(
    //   MasterDataStorageKeys.Genres,
    // );

    const result = await _getMasterData(
      isReload,
      MasterDataStorageKeys.Genres,
      MasterDataController().getGenres,
    );

    if (!result.isSuccess) {
      throw new Error(`Unable to fetch genres ${result.message}`);
    }

    // if (!genres || isReload) {
    //   const fetchedGenres = await MasterDataController().getGenres();
    //   if (!fetchedGenres.success) {
    //     throw new Error(
    //       `Unable to fetch audioVisualTypes ${fetchedGenres.message}`,
    //     );
    //   }

    //   genres = fetchedGenres.data;
    //   StorageService().setToLocalStorage<GenreDto[]>(
    //     MasterDataStorageKeys.Genres,
    //     genres,
    //     new Timespan({ minutes: 10 }),
    //   );
    // }

    return result.data;
  }

  async function getAudioVisualSourcse(
    isReload: boolean,
  ): Promise<AudioVisualSourceDto[]> {
    const result = await _getMasterData(
      isReload,
      MasterDataStorageKeys.AudioVisualSources,
      MasterDataController().getAudioVisualSources,
    );

    if (!result.isSuccess) {
      throw new Error(`Unable to fetch audioVisualSources ${result.message}`);
    }

    // let audioVisualTypes = StorageService().getFromLocalStorage<
    //   AudioVisualTypeDto[]
    // >(MasterDataStorageKeys.AudioVisualTypes);

    // if (!audioVisualTypes || isReload) {
    //   const fetchedAudioVisualTypes =
    //     await MasterDataController().getAudioVisualTypes();
    //   if (!fetchedAudioVisualTypes.success) {
    //     throw new Error(
    //       `Unable to fetch audioVisualTypes ${fetchedAudioVisualTypes.message}`,
    //     );
    //   }

    //   audioVisualTypes = fetchedAudioVisualTypes.data;
    //   StorageService().setToLocalStorage<AudioVisualTypeDto[]>(
    //     MasterDataStorageKeys.AudioVisualTypes,
    //     audioVisualTypes,
    //     new Timespan({ minutes: 10 }),
    //   );
    // }

    // return audioVisualTypes;

    return result.data;
  }

  interface MasterDataResult<T> {
    isSuccess: boolean;
    message?: string;
    data: T[];
  }

  async function _getMasterData<T>(
    isReload: boolean,
    storageKey: string,
    sourceFunction: () => Promise<ApiResult<T[]>>,
  ): Promise<MasterDataResult<T>> {
    let data = StorageService().getFromLocalStorage<T[]>(storageKey);

    if (!data || isReload) {
      const fetchedGenres = await sourceFunction();
      if (!fetchedGenres.success) {
        return {
          isSuccess: false,
          message: fetchedGenres.message,
          data: [],
        };
      }

      data = fetchedGenres.data;
      StorageService().setToLocalStorage<T[]>(
        storageKey,
        data,
        new Timespan({ minutes: 10 }),
      );
    }

    return {
      isSuccess: true,
      data,
    };
  }

  return { getAudioVisualTypes, getGenres, getAudioVisualSourcse };
}
