import {
  AddAudioVisualDto,
  AudioVisualDto,
  AudioVisualTypeDto,
  AudioVisualTypeEnum,
} from "../api/ApiClient";
import { AudioVisualController } from "../api/controllers/AudioVisualController";
import { ApiResult } from "../api/types/ApiResult";
import { DataStorageKeys } from "../enums/StorageKeys";
import { StorageService } from "./StorageService";

export function AudioVisualService() {
  const audioVisualController = AudioVisualController();

  async function getAudioVisualByType(
    type: AudioVisualTypeEnum,
    isReload: boolean,
  ): Promise<AudioVisualTypeDto[]> {
    const storageKey =
      type === AudioVisualTypeEnum.Movie
        ? DataStorageKeys.Movies
        : DataStorageKeys.Series;

    let audioVisuals =
      StorageService().getFromSessionStorage<AudioVisualTypeDto[]>(storageKey);

    if (!audioVisuals || isReload) {
      const fetchedMovies =
        await audioVisualController.getAudioVisualByType(type);
      if (!fetchedMovies.success) {
        throw new Error(`Unable to fetch ${type}s ${fetchedMovies.message}`);
      }

      audioVisuals = fetchedMovies.data;
      StorageService().setToSessionStorage<AudioVisualTypeDto[]>(
        storageKey,
        audioVisuals,
      );
    }

    return audioVisuals;
  }

  async function getAudioVisuals(isReload: boolean): Promise<AudioVisualDto[]> {
    let audioVisuals = StorageService().getFromSessionStorage<AudioVisualDto[]>(
      DataStorageKeys.AudioVisuals,
    );

    if (!audioVisuals || isReload) {
      const fetchedAudioVisuals = await audioVisualController.getAudioVisuals();
      if (!fetchedAudioVisuals.success) {
        throw new Error(
          `Unable to fetch audioVisuals ${fetchedAudioVisuals.message}`,
        );
      }

      audioVisuals = fetchedAudioVisuals.data;
      StorageService().setToSessionStorage<AudioVisualDto[]>(
        DataStorageKeys.AudioVisuals,
        audioVisuals,
      );
    }

    return audioVisuals;
  }

  async function clearAudioVisualStorage() {
    StorageService().removeFromSessionStorage(DataStorageKeys.AudioVisuals);
  }

  async function addAudioVisual(
    audioVisual: AddAudioVisualDto,
  ): Promise<ApiResult<AudioVisualDto>> {
    const result = await audioVisualController.addAudioVisual(audioVisual);
    if (!result.success) {
      alert("Problem adding audiovisual: " + result.message);
      return result;
    }

    StorageService().appendToSessionStorage<AudioVisualDto[]>(
      DataStorageKeys.AudioVisuals,
      [result.data],
    );

    return result;
  }

  async function deleteAudioVisual(audioVisualId: number) {
    const result = await audioVisualController.deleteAudioVisual(audioVisualId);
    if (!result.success) {
      throw new Error(`Unable to delete audioVisual ${result.message}`);
    }

    StorageService().removeFromSessionStorageValue<AudioVisualDto[]>(
      DataStorageKeys.AudioVisuals,
      (list) => {
        const item = list.find((i) => i.id === audioVisualId);
        if (!item) {
          return;
        }

        list.splice(list.indexOf(item), 1);
      },
    );
  }

  return {
    getAudioVisualByType,
    getAudioVisuals,
    addAudioVisual,
    deleteAudioVisual,
    clearAudioVisualStorage,
  };
}
