import { ApiException } from "../ApiClient";
import { ApiResult } from "../types/ApiResult";
import { ValidationResult } from "../types/ValidationResult";

export function handleApiResponse<ResponseType>(
  response: Promise<ResponseType>,
  errorParser?: (apiException: string) => string,
): Promise<ApiResult<ResponseType>> {
  return response
    .then((response) => {
      const resultObject: ApiResult<ResponseType> = {
        success: true,
        data: response,
      };
      return Promise.resolve(resultObject);
    })
    .catch((error: ApiException) => {
      const resultObject: ApiResult<ResponseType> = {
        success: false,
        data: {} as ResponseType,
        message: error.response,
      };

      if (error.status === 401) {
        resultObject.message = "UNAUTHORIZED";
      }

      if (error.status === 400) {
        const validationResult = tryParseValidationError(error.response);
        if (validationResult) {
          for (const property in validationResult.errors) {
            resultObject.message =
              validationResult.errors[property].join("\r\n");
          }
        }
      }

      return Promise.resolve(resultObject);
    });
}

function tryParseValidationError(json: string): ValidationResult | undefined {
  try {
    const result = JSON.parse(json) as ValidationResult;
    if (result?.errors) {
      return result;
    }

    return undefined;
  } catch {
    return undefined;
  }
}
