import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Languages } from "../enums/Languages";

export function LanguageSelectorComponent() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const handleLanguageChange = (
    event: React.MouseEvent<HTMLElement>,
    language: Languages,
  ) => {
    i18n.changeLanguage(language);
    setLanguage(language);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      exclusive
      value={language}
      onChange={handleLanguageChange}
      aria-label="Platform"
    >
      <ToggleButton value={Languages.English}>EN</ToggleButton>
      <ToggleButton value={Languages.Norwegian}>NO</ToggleButton>
    </ToggleButtonGroup>
  );
}
