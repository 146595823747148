import { Box, Grid, Tab, Tabs } from "@mui/material";
import Home from "@mui/icons-material/Home";
import Tv from "@mui/icons-material/Tv";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { Screens } from "../enums/Screens";
import { LanguageSelectorComponent } from "./LanguageSelectorComponent";

interface NavigationBarProps {
  changeScreenCallback: { (value: Screens): void };
  selectedValue: Screens;
}

export default function NavigationBarComponent(props: NavigationBarProps) {
  const [value, setValue] = useState<Screens>(props.selectedValue);
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      props.changeScreenCallback(Screens.Home);
    }
  }, [props, isAuthenticated]);

  const handleChange = (event: React.SyntheticEvent, newValue: Screens) => {
    setValue(newValue);
  };

  useEffect(() => {
    props.changeScreenCallback(value);
  }, [props, value]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={0}>
            <Tabs
              value={value}
              aria-label="basic tabs example"
              onChange={handleChange}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <Tab
                label={t("homeNavigation")}
                value={Screens.Home}
                icon={<Home />}
              />
              <Tab
                disabled={!isAuthenticated}
                label={t("moviesTvSeriesNavigation")}
                value={Screens.AudioVisual}
                icon={<Tv />}
              />
            </Tabs>
          </Grid>

          <Grid item xs={0}>
            <LanguageSelectorComponent />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
