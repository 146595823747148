import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "../types/Component";
import { Id } from "../types/Content";
import { MasterDataService } from "../services/MasterDataService";
import {
  AddAudioVisualDto,
  AudioVisualSourceDto,
  AudioVisualTypeDto,
  AudioVisualTypeEnum,
  GenreDto,
} from "../api/ApiClient";
import { AudioVisualService } from "../services/AudioVisualService";

export function AddAudioVisualComponent(props: Loading & Id) {
  const [audioVisualTypes, setAudioVisualTypes] =
    useState<AudioVisualTypeDto[]>();
  const [genres, setGenres] = useState<GenreDto[]>();
  const [audioVisualSources, setAudioVisualSources] =
    useState<AudioVisualSourceDto[]>();

  const [selectedAudioVisualType, setSelectedAudioVisualType] = useState(
    AudioVisualTypeEnum.Movie,
  );
  const [hasLoadingErrors, setHasLoadingErrors] = useState(false);

  const [selectedGenres, setSelectedGenres] = useState<number[]>([]);
  const [selectedAudioVisualSources, setSelectedAudioVisualSources] = useState<
    number[]
  >([]);
  const [selectedName, setSelectedName] = useState<string>("");
  const { t } = useTranslation();

  async function loadMasterData() {
    try {
      setHasLoadingErrors(false);
      props.isLoadingCallback(true);
      const audioVisualTypes =
        await MasterDataService().getAudioVisualTypes(false);
      const genreTypes = await MasterDataService().getGenres(false);
      const audioVisualSources =
        await MasterDataService().getAudioVisualSourcse(false);

      setAudioVisualTypes(audioVisualTypes);
      setGenres(genreTypes);
      setAudioVisualSources(audioVisualSources);
    } catch (error) {
      setHasLoadingErrors(true);
      console.log("Problem loading masterdata", error);
    } finally {
      props.isLoadingCallback(false);
    }
  }

  useEffect(() => {
    loadMasterData();
  }, []);

  const items =
    audioVisualTypes &&
    audioVisualTypes.map((audioVisualType, index) => (
      <FormControlLabel
        key={index}
        value={audioVisualType.audioVisualType.toString()}
        control={<Radio />}
        label={audioVisualType.name}
      />
    ));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleGenreChange = (
    event: SelectChangeEvent<typeof selectedGenres>,
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedGenres(typeof value === "string" ? [] : value);
  };

  const handleAudioVisualSourceChange = (
    event: SelectChangeEvent<typeof selectedAudioVisualSources>,
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedAudioVisualSources(typeof value === "string" ? [] : value);
  };

  const genreExists = (genre: GenreDto) => {
    const test = !!selectedGenres?.find((id) => id === genre.id);
    return test;
  };

  const audioVisualSourceExists = (audioVisualSource: AudioVisualSourceDto) => {
    const test = !!selectedAudioVisualSources?.find(
      (id) => id === audioVisualSource.id,
    );
    return test;
  };

  const getGenreDto = (genreId: number) => {
    const test = genres?.find((dto) => dto.id === genreId);
    return test;
  };

  const getAudioVisualSourceDto = (audioVisualSourceId: number) => {
    const test = audioVisualSources?.find(
      (dto) => dto.id === audioVisualSourceId,
    );
    return test;
  };

  async function handleAddAudioVisual() {
    const result = await AudioVisualService().addAudioVisual({
      name: selectedName,
      genres: selectedGenres,
      audioVisualSources: selectedAudioVisualSources,
      audioVisualType: selectedAudioVisualType,
    } as AddAudioVisualDto);
    if (result.success) {
      setSelectedName("");
    }
  }

  return hasLoadingErrors ? (
    <Typography>{t("problemLoadingMasterData")}</Typography>
  ) : (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="left"
    >
      <Grid item>
        <FormControl sx={{ m: 1, width: 300 }}>
          <FormLabel>{t("audioVisualType")}</FormLabel>
          <RadioGroup
            row
            color="primary"
            name="row-radio-buttons-group"
            aria-label="Platform"
            value={selectedAudioVisualType}
            onChange={(_, value) =>
              setSelectedAudioVisualType(value as AudioVisualTypeEnum)
            }
          >
            {items}
          </RadioGroup>
        </FormControl>

        <FormControl sx={{ m: 1, width: 300 }}>
          <FormLabel>{t("audioVisualGenre")}</FormLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedGenres}
            onChange={handleGenreChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) =>
              selected.map((x) => getGenreDto(x)?.name).join(", ")
            }
            MenuProps={MenuProps}
          >
            {genres &&
              genres.map((genre) => (
                <MenuItem key={genre.id} value={genre.id}>
                  <Checkbox checked={genreExists(genre)} />
                  <ListItemText primary={genre.name} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, width: 300 }}>
          <FormLabel>{t("audioVisualSource")}</FormLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedAudioVisualSources}
            onChange={handleAudioVisualSourceChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) =>
              selected.map((x) => getAudioVisualSourceDto(x)?.name).join(", ")
            }
            MenuProps={MenuProps}
          >
            {audioVisualSources &&
              audioVisualSources.map((audioVisualSources) => (
                <MenuItem
                  key={audioVisualSources.id}
                  value={audioVisualSources.id}
                >
                  <Checkbox
                    checked={audioVisualSourceExists(audioVisualSources)}
                  />
                  <ListItemText primary={audioVisualSources.name} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>

      <FormControl>
        <FormLabel>{t("audioVisualName")}</FormLabel>
        <Input
          style={{ width: 300, borderRadius: 8, border: 1 }}
          id="my-input"
          placeholder="name"
          value={selectedName}
          onChange={(event) => setSelectedName(event.target.value)}
        />
      </FormControl>

      <Button
        style={{ marginTop: 30 }}
        variant="contained"
        onClick={() => handleAddAudioVisual()}
      >
        {t("addAudioVisualButton")}
      </Button>
    </Grid>
  );
}
