import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Languages } from "./enums/Languages";
import { StorageService } from "./services/StorageService";
import { MiscStorageKeys } from "./enums/StorageKeys";

const resources: { [key: string]: {} } = {}
resources[Languages.Norwegian] = {
    translation: {
        "loginButton": "Logg deg på",
        "welcomeHomeText": "Velkommen hjem!",
        "moviesTvSeries": "Filmer og TV-Serier",
        "movie": "Film",

        "moviesTvSeriesNavigation": "Filmer/TvSerier",
        "homeNavigation": "Hjem",

        "plural": "er",
    }
}

resources[Languages.English] = {
    translation: {
        "loginButton": "Sign in",
        "welcomeHomeText": "Welcome home!",
        "moviesTvSeries": "Movies and Tv-Series",
        "movie": "Movie",

        "moviesTvSeriesNavigation": "Movies/TvSeries",
        "homeNavigation": "Home",

        "plural": "s",
    }
}

const savedLanguage = StorageService()
    .getWithDefaultFromLocalStorage<Languages>(MiscStorageKeys.SelectedLanguage, () => Languages.English);

i18n
    .use(initReactI18next) // passes i18n down to react-i18next

    .init({
        resources,
        lng: savedLanguage,
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

i18n.on('languageChanged', (language) => {
    StorageService().setToLocalStorage(MiscStorageKeys.SelectedLanguage, language);
})

export default i18n;