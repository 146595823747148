export enum MiscStorageKeys {
  SelectedLanguage = "SelectedLanguage",
  SelectedScreenId = "SelectedScreenId",
}

export enum MasterDataStorageKeys {
  AudioVisualTypes = "AudioVisualTypes",
  Genres = "Genres",
  AudioVisualSources = "AudioVisualSources",
}

export enum DataStorageKeys {
  Movies = "Movies",
  Series = "Series",
  AudioVisuals = "AudioVisuals",
}
