import {
  Backdrop,
  Box,
  CircularProgress,
  CircularProgressProps,
  Grid,
  Typography,
} from "@mui/material";
import CollectionsIcon from "@mui/icons-material/Collections";
import AddIcon from "@mui/icons-material/LibraryAdd";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { Screens } from "../enums/Screens";
import { Id } from "../types/Content";
import { ContentComponent } from "../components/ContentComponent";
import { AudioVisualListComponent } from "../components/AudioVisualListComponent";
import { AddAudioVisualComponent } from "../components/AddAudioVisualComponent";

export default function AudioVisualScreen(_: Id) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("audioVisualList");
  const [selectedContentId, setSelectedContentId] = useState("audioVisualList");

  function handleIsLoading(isLoading: boolean): void {
    // setSelectedTab("loading");
    // setSelectedContentId("loading");
    setIsLoading(isLoading);
  }

  // console.log(isLoading);

  function tabChanged(tabId: string): void {
    setSelectedTab(tabId);
  }
  const view = (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h2">{t("moviesTvSeries")}</Typography>
        <Grid container spacing={1} justifyContent="flex-start">
          <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              aria-label="basic tabs example"
              onChange={(a, b) => tabChanged(b)}
              TabIndicatorProps={{
                style: {
                  display: "none",
                },
              }}
            >
              <Tab
                disabled={isLoading}
                value="audioVisualList"
                icon={<CollectionsIcon />}
                label={t("showAudioVisual")}
              />
              <Tab
                value="addAudioVisual"
                disabled={isLoading}
                icon={<AddIcon />}
                label={t("addAudioVisual")}
              />
            </Tabs>
          </Box>
        </Grid>

        <>
          <ContentComponent selectedContentId={selectedTab}>
            <AudioVisualListComponent
              contentId="audioVisualList"
              isLoadingCallback={handleIsLoading}
            />
            <AddAudioVisualComponent
              contentId="addAudioVisual"
              isLoadingCallback={handleIsLoading}
            />
          </ContentComponent>
        </>

        <Backdrop sx={{ color: "#fff", zIndex: 1 }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  );

  return { ...view, id: Screens.AudioVisual };
}
