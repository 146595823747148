import { useState, useEffect } from "react";

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.addEventListener("beforeunload", (event) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    }
  });
};

// Hook
export function useExitPrompt(bool: boolean) {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);

  window.addEventListener("onload", (event) => {
    initBeforeUnLoad(showExitPrompt);
  });

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt];
}

export function useOnUnload(func: () => void) {
  window.addEventListener("beforeunload", (event) => {
    func();
  });

  return [useOnUnload];
}
