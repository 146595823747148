import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n";
import { MsalProvider } from "@azure/msal-react";
import {
  AuthenticationResult,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { msalConfig } from "./MsalConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

if (window.location.hash !== "") {
  console.log("hash found" + window.location.hash);
} else {
  root.render(
    <React.StrictMode>
      <App />,
    </React.StrictMode>,
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
