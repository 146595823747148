import Typography from "@mui/material/Typography";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import LoginComponent from "../components/LoginComponent";
import { Id } from "../types/Content";

export default function MainScreen(_: Id) {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h2">{t("welcomeHomeText")}</Typography>
      <AuthenticatedTemplate>
        <Typography>You are authenticated!</Typography>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginComponent />
      </UnauthenticatedTemplate>
    </>
  );
}
